import api from '@/requests';
import store from '@/store';

// #region Вспомогательные функции
// Функция для получения области координат
function getCoordinateArea(bounds) {
    const [[minLat, minLng], [maxLat, maxLng]] = bounds;
    return {
        minLat,
        minLng,
        maxLat,
        maxLng
    };
}
// #endregion

// Функция для получения сущностей
async function fetchEntities(signal) {
  const bounds = store.getters['map/bounds'];

    if (!bounds) {
      store.dispatch('entity/setEntities', []);
    }

    const coordinateArea = getCoordinateArea(bounds);
    const layer = store.getters['layer/layer'];

    try {
        if (layer === 'partners') {
            const items = await api.partners.listByCoordinateArea(coordinateArea, signal);

            if (!signal || !signal.aborted) {
              store.dispatch('entity/setEntities', items);
            } else {
              console.warn('Запрос был отменён');
            }
        } else if (layer === 'objects') {
          // TODO: Реализовать запрос объектов по координатам
          store.dispatch('entity/setEntities', []);
        }
    } catch (error) {
        if (error.name === 'AbortError') {
          console.warn('Запрос был отменён');
        } else {
          console.error('Произошла ошибка', error);
        }
    }
}

// Функция для фильтрации сущностей
async function fetchEntitiesFilter(signal) {
  const layer = store.getters['layer/layer'];
  const bounds = store.getters['map/bounds'];
  const filter = store.getters['filter/filterValues'];
  const coordinateArea = getCoordinateArea(bounds);

  try {
    if (layer === 'partners') {
      const data = await api.partners.filter(coordinateArea, filter, signal);

      if (!signal || !signal.aborted) {
        store.dispatch('entity/setEntities', data.items);
        store.dispatch('filter/setFilterId', data.meta.id);
        store.dispatch('filter/setCount', data.meta.count);
      } else {
        console.warn('Запрос был отменён');
      }
    }
  } catch (error) {
    if (error.name === 'AbortError') {
      console.warn('Запрос был отменён');
    } else {
      console.error('Произошла ошибка', error);
    }
  }
}

// Функция для фильтрации сущностей по идентификатору
async function fetchEntityFilterById(id, signal) {
  const layer = store.getters['layer/layer'];
  const bounds = store.getters['map/bounds'];
  const coordinateArea = getCoordinateArea(bounds);

  try {
    if (layer === 'partners') {
      const data = await api.partners.filterById(id, coordinateArea, signal);

      if (!signal || !signal.aborted) {
        store.dispatch('filter/setFilterId', data.meta.id);
        store.dispatch('filter/setCount', data.meta.count);
        store.dispatch('filter/selectDirection', data.meta.filters.directions ?? []);
        store.dispatch('filter/selectPartnerType', data.meta.filters.partnerTypes ?? []);
        store.dispatch('filter/selectPartnerAttribute', data.meta.filters.partnerAttributes ?? []);
        store.dispatch('filter/selectProduct', data.meta.filters.products ?? []);
        store.dispatch('entity/setEntities', data.items);
      } else {
        console.warn('Запрос был отменён');
      }
    } else if (layer === 'objects') {
      // TODO: Реализовать запрос объектов по идентификатору
    }
  } catch (error) {
    if (error.name === 'AbortError') {
      console.warn('Запрос был отменён');
    } else {
      console.error('Произошла ошибка', error);
    }
  }
}

// #region Поиск сущностей
// Функция для поиска сущностей
async function fetchSearch(layer, query, bounds, signal) {
    store.dispatch('loader/startLoading');

    const coordinateArea = getCoordinateArea(bounds);

    try {
        if (layer === 'partners') {
            const data = await api.partners.searchByCoordinate(query, coordinateArea, signal);

            if (!signal || !signal.aborted) {
                store.dispatch('entity/setEntities', data.items ?? []);
                store.dispatch('filter/setCount', data.meta.count);
                store.dispatch('loader/stopLoading');
            } else {
                console.warn('Запрос был отменён');
                store.dispatch('loader/stopLoading');
            }
        }
    } catch (error) {
        if (error.name === 'AbortError') {
            console.warn('Запрос был отменён');
        } else {
            console.error('Произошла ошибка', error);
        }
        store.dispatch('loader/stopLoading');
    }
}

// Функция для поиска сущностей
async function fetchEntitiesSearch(signal = undefined) {
    store.dispatch('loader/startLoading');

    const layer = store.getters['layer/layer'];
    const query = store.getters['search/searchQuery'];
    let bounds = store.getters['map/bounds'];

    if (!bounds) {
        store.dispatch('loader/startLoading');
        const interval = setInterval(async () => {
            if (!bounds) {
                bounds = store.getters['map/bounds'];
            }
            if (bounds) {
                clearInterval(interval);
                await fetchSearch(layer, query, bounds, signal);
            }
            store.dispatch('loader/stopLoading');
        }, 500);
    } else {
        await fetchSearch(layer, query, bounds, signal);
    }

    store.dispatch('loader/stopLoading');
}
// #endregion

const entity = {
    list: fetchEntities,
    filter: fetchEntitiesFilter,
    filterById: fetchEntityFilterById,
    search: fetchEntitiesSearch
};

export default entity;
