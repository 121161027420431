const state = {
    query: ''
};

const actions = {
    setQuery({ commit }, query) {
        commit('SET_QUERY', query);
    },
    clearQuery({ commit }) {
        commit('SET_QUERY', '');
    }
};

const mutations = {
    SET_QUERY(state, query) {
        state.query = query;
    }
};

const getters = {
    searchQuery: state => state.query
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
