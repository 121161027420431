import api from '@/requests';
import store from '@/store';

async function getDirections() {
  store.dispatch('loader/startLoading');
  const response = await api.filters.directions();
  if (response) {
    store.dispatch('filter/setDirections', response.items);
  } else {
    store.dispatch('filter/setDirections', []);
    console.error('Произошла ошибка при получении списка направлений');
  }
  store.dispatch('loader/stopLoading');
}

async function getPartnerTypes() {
  store.dispatch('loader/startLoading');
  const response = await api.filters.partnerTypes();
  if (response) {
    store.dispatch('filter/setPartnerTypes', response.items);
  } else {
    store.dispatch('filter/setPartnerTypes', []);
    console.error('Произошла ошибка при получении списка типов партнеров');
  }
  store.dispatch('loader/stopLoading');
}

async function getPartnerAttributes() {
  store.dispatch('loader/startLoading');
  const response = await api.filters.partnerAttributes();
  if (response) {
    store.dispatch('filter/setPartnerAttributes', response.items);
  } else {
    store.dispatch('filter/setPartnerAttributes', []);
    console.error('Произошла ошибка при получении списка атрибутов партнеров');
  }
  store.dispatch('loader/stopLoading');
}

async function getProductGroups() {
  store.dispatch('loader/startLoading');
  const response = await api.filters.productGroups();
  if (response) {
    store.dispatch('filter/setProductGroups', response.items);
  } else {
    store.dispatch('filter/setProductGroups', []);
    console.error('Произошла ошибка при получении списка групп продуктов');
  }
  store.dispatch('loader/stopLoading');
}

const filter = {
    directionList: getDirections,
    partnerTypeList: getPartnerTypes,
    partnerAttributeList: getPartnerAttributes,
    productGroupList: getProductGroups
}

export default filter;
