<template>
  <div v-if="isVisible" :key="'over-' + item.id">
    <ymap-marker :key="item.id + '-marker-' + interactionKey" :properties="properties" :coords="coords"
      :marker-id="item.id" :icon="icon" :options="options()" :cluster-name="layer"
      :hint-content="hintContent()" @click="openCard" @mouseenter="hovered = true" @mouseleave="hovered = false" />
  </div>
</template>

<script>
import { ymapMarker } from 'vue-yandex-maps';
import { mapGetters, mapActions } from 'vuex';
import markerHelper from '@/helpers/markerHelper';

export default {
  name: 'MapMarker',
  props: {
    item: Object
  },
  components: {
    ymapMarker
  },
  data() {
    return {
      interactionKey: 0,
      hovered: false,
      isVisible: true,
      icon: {
        layout: 'default#imageWithContent',
        imageHref: '/',
        imageSize: [70, 70]
      }
    }
  },
  watch: {
    layout() {
      this.interactionKey += 1;
    }
  },
  methods: {
    openCard() {
      this.startLoading();
      this.hovered = false;
      this.setVisited(this.item.id);
      this.setEntityId(this.item.id);
      // TODO: refactor
      this.$store.dispatch('setActiveItem', this.item.id);
      this.$router.push(`/${this.layer}/${this.item.id}`);
      this.stopLoading();
    },
    hintContent() {
      if (this.layer === 'partners' && !this.item.is_sertified) {
        return `<div class="map-marker__content map-marker__content--hint ${this.item.visited ? 'map-marker__content--visited' : ''}">
                    <p class="map-marker__name">${this.item.name}</p>
                    <p class="map-marker__type">${this.item.type.name}</p>
                    </div>
                 </div>`;
      }
      return '';
    },
    isActive() {
      const activeItem = this.$store.getters['getActiveItem'];
      return this.item.id === activeItem;
    },
    options() {
      return markerHelper.options(this.item.directions);
    },
    ...mapActions('loader', ['startLoading', 'stopLoading']),
    ...mapActions('entity', ['setEntityId', 'setVisited'])
  },
  computed: {
    properties() {
      const iconOptions = {
        image: this.item.type.image,
        link: this.item.type.link,
        isHovered: this.hovered
      };
      const itemInfo = {
        name: this.item.name,
        type: this.item.type.name
      };

      return markerHelper.markerLayout(this.item.directions, iconOptions, this.layer, itemInfo, this.hovered, this.isActive(), this.item.visited, this.isVisible, this.item.is_sertified);
    },
    coords() {
      return [this.item.latitude, this.item.longitude];
    },
    ...mapGetters('layer', ['layer'])
  }
}
</script>

<style scoped>
</style>
