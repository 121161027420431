import api from '@/requests';
import store from '@/store';
import AuthHelper from './AuthHelper';

async function getUserInfo() {
  store.dispatch('loader/startLoading');
  const accessToken = AuthHelper.checkAuth();
  if (accessToken) {
    const response = await api.user.info();
    if (response) {
      store.dispatch('user/setAuthUser', response);
    } else {
      store.dispatch('user/setAuthUser', {});
      console.error('Произошла ошибка при получении информации о пользователе');
    }
  }
  store.dispatch('loader/stopLoading');
}

const user = {
  info: getUserInfo
};

export default user;
