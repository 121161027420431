const state = {
  username: ''
};

const actions = {
  setAuthUser({ commit }, value) {
    commit('SET_AUTH_USER', value);
  }
};

const mutations = {
  SET_AUTH_USER(state, value) {
    state.username = value.username;
  }
};

const getters = {
  username: state => state.username,
  isAuthenticated: state => !!state.username
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
