import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/partners',
    component: () => import('@/components/sidebar/SidebarIndex.vue')
  },
  {
    path: '/objects',
    component: () => import('@/components/sidebar/SidebarIndex.vue')
  },
  {
    path: '/partners/search',
    component: () => import('@/components/sidebar/SidebarResultsList.vue')
  },
  {
    path: '/objects/search',
    component: () => import('@/components/sidebar/SidebarResultsList.vue')
  },
  {
    path: '/partners/filters',
    component: () => import('@/components/sidebar/SidebarFilters.vue')
  },
  {
    path: '/objects/filters',
    component: () => import('@/components/sidebar/SidebarFilters.vue')
  },
  {
    path: '/partners/filter/results',
    component: () => import('@/components/sidebar/SidebarResultsList.vue')
  },
  {
    path: '/objects/filter/results',
    component: () => import('@/components/sidebar/SidebarResultsList.vue')
  },
  {
    path: '/partners/:id',
    component: () => import('@/components/card/CardEntity.vue'),
    meta: {
      hasScroll: false
    }
  },
  {
    path: '/objects/:id',
    component: () => import('@/components/FacilityCard.vue'),
    meta: {
      hasScroll: false
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// Глобальный обработчик для сброса фильтра и поиска при переходе на новый маршрут
router.beforeEach((to, from, next) => {
  if ((to.path !== '/partners/filters' && to.path !== '/objects/filters') && (to.path !== '/partners/filter/results' && to.path !== '/objects/filter/results')) {
      store.dispatch('filter/clearFilter'); // Вызов действия сброса фильтра в Store
  }

  if (to.path !== '/partners/search' && to.path !== '/objects/search') {
    store.dispatch('search/clearQuery');
  }

  next(); // Переход на новый маршрут
});

export default router;
