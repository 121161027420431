import axios from '../axios';

const list = async () => {
  const response = await axios.get('/api/v1/map/partner/list');
  return response.data.items || [];
};

const listByCoordinateArea = async (coordinateArea, signal) => {
  const response = await axios.post('/api/v1/map/partner/list', coordinateArea, { signal });
  return response.data.items || [];
}

const partner = async (id) => {
  const response = await axios.get(`/api/v1/map/partner/${id}`);
  return response.data.item || {};
};

const search = async (query) => {
  const response = await axios.get(`/api/v1/map/partner/search?query=${query}`);
  return response.data.items || [];
};

const searchByCoordinate = async (query, coordinateArea, signal) => {
  const response = await axios.post(`/api/v1/map/partner/search?query=${query}`, coordinateArea, { signal });
  return response.data || null;
}

const filter = async (area, filters, signal) => {
  const response = await axios.post('/api/v1/map/partner/filter', { area, filters }, { signal });
  return response.data || null;
};

const filterById = async (id, area, signal) => {
  const response = await axios.post(`/api/v1/map/partner/filter?context=${id}`, { area }, { signal });
  return response.data || null;
};

const partners = {
  list,
  listByCoordinateArea,
  partner,
  search,
  searchByCoordinate,
  filter,
  filterById
};

export default partners;
