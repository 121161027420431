const state = {
  isCookieVisible: false // Показать куки
};

const actions = {
  setCookieVisible({ commit }, value) {
    commit('SET_COOKIE_VISIBLE', value);
  }
};

const mutations = {
  SET_COOKIE_VISIBLE(state, value) {
    state.isCookieVisible = value;
  }
};

const getters = {
  isCookieVisible: state => state.isCookieVisible
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
