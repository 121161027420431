import Vue from 'vue';
import Vuex from 'vuex';
import state from '@/store/state';
import actions from '@/store/actions';
import getters from '@/store/getters';
import mutations from '@/store/mutations';
import loader from './modules/loader';
import layer from './modules/layer';
import map from './modules/map';
import entity from './modules/entity';
import location from './modules/location';
import search from './modules/search';
import filter from './modules/filter';
import modal from './modules/modal';
import cookie from './modules/cookie';
import user from './modules/user';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
      entity,
      loader,
      layer,
      map,
      location,
      search,
      filter,
      modal,
      cookie,
      user
    },
    state: {
      ...state
    },
    mutations: {
      ...mutations
    },
    actions: {
      ...actions
    },
    getters: {
      ...getters
    }
})
