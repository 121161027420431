export default {
  getActiveLayer: state => state.activeLayer,
  getUser: state => state.authUser,
  isAuth: state => state.authUser !== null,
  getCoords: state => state.userLocation,
  getCurrCoords: state => state.currentCoords,
  getBusinessRegions: state => state.businessRegions,
  getGeoRegions: state => state.geoRegions,
  getDirections: state => state.directions,
  getObjectTypes: state => state.objectTypes,
  getPartnerTypes: state => state.partnerTypes,
  getPartnerAttributes: state => state.partnerAttributes,
  getObjects: state => state.objects,
  getPartners: state => state.partners,
  getAll: state => {
    if (state.objects.length && state.partners.length) {
      return state.objects.concat(state.partners);
    }
    if (state.partners.length) {
      return state.partners;
    }
    if (state.objects.length) {
      return state.objects;
    }
    return [];
  },
  getAvailables: state => state.availables,
  getVisibles: state => state.visibles,
  getActiveDirections: state => state.activeDirections,
  getActiveItem: state => state.activeItemId,
  getSearchResults: state => state.searchResults,
  getGroups: state => state.productGroups,
  getProducts: state => state.products,
  getProductsByGroup: state => groupId => state.products.filter(product => product.product_group === groupId),
  getSocials: state => state.socials,
  getDot: state => state.dot,
  getChoosingLocationStatus: state => state.isChoosingLocation,
  getLocationName: state => state.locationName,
  getPartnerFilter: state => state.partnerFilter,
  getPartnerFilterCertificate: state => state.partnerCertificateFilter,
  getObjectFilter: state => state.objectsFilter,
  getObjectRangeFilter: state => state.objectRangeFilter,
  getIsLoading: state => state.isLoading
}
