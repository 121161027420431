const state = {
    loadingCount: 0 // Счётчик активных операций
};

const actions = {
    startLoading({ commit }) {
        commit('START_LOADING');
    },
    stopLoading({ commit }) {
        commit('STOP_LOADING');
    }
};

const mutations = {
    START_LOADING(state) {
        state.loadingCount++;
    },
    STOP_LOADING(state) {
        if (state.loadingCount > 0) {
            state.loadingCount--;
        }
    }
};

const getters = {
    getIsLoading: state => state.loadingCount > 0
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
