<template>
  <div class="auth-reminder no-select">
    Вы авторизованы как
    <b>{{ username }}</b>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AuthReminder',
  computed: {
    ...mapGetters('user', ['username'])
  }
}
</script>

<style scoped lang="scss">
.auth-reminder {
  position: absolute;
  top: 30px;
  left: 20px;
  padding: 6px 10px 9px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  background-color: #58BCAF;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  z-index: 1;

  @media (max-width: 1023px) {
    display: none;
  }

  b {
    display: block;
  }
}

.no-select {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
</style>
