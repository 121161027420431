const state = {
    value: '', // 'partners' или 'objects'
    isLayerModalVisible: false // Видимость модального окна
};

const actions = {
    setLayer({ commit }, layer) {
        commit('SET_LAYER', layer);
    },
    setLayerModalVisible({ commit }, value) {
        commit('SET_LAYER_MODAL_VISIBLE', value);
    },
    defineLayer({ commit }, { route, router }) {
      let layer = '';

      if (route.path.includes('partners')) {
        layer = 'partners';
      } else if (route.path.includes('objects')) {
        layer = 'objects';
      } else if (route.fullPath === '/') {
        layer = 'partners';
        router.replace('/partners');
      }

      if (route.path.includes(`${layer}/filters`)) {
        router.replace(`/${layer}`);
      }

      commit('SET_LAYER', layer);
    }
};

const mutations = {
    SET_LAYER(state, layer) {
        state.value = layer;
    },
    SET_LAYER_MODAL_VISIBLE(state, value) {
        state.isLayerModalVisible = value;
    }
};

const getters = {
    layer: state => state.value,
    isLayerModalVisible: state => state.isLayerModalVisible
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
