import partners from './modules/partners';
import filters from './modules/filters';
import user from './modules/user';

const api = {
    partners,
    filters,
    user
};

export default api;
