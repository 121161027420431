<template>
  <div class="sidebar-top">
    <a @click="logoHandle()">
      <img src="@/assets/images/svg/logo.svg" alt="РЕХАУ" class="logo">
    </a>
    <div class="location-block">
      <span class="caption">Выбрать город</span>
      <span class="city" @click="setShowLocationChoiceModal(true)">{{ userLocationName }}</span>
    </div>
  </div>
</template>

<script lang="js">
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SidebarHeader',
  async mounted() {
    if (window.ymaps && window.ymaps.geocode) {
      await this.defineRegion();
    } else {
      const interval = setInterval(async () => {
        if (window.ymaps && window.ymaps.geocode) {
          clearInterval(interval);
          await this.defineRegion();
        }
      }, 300);
    }
  },
  methods: {
    // Определение региона пользователя
    async defineRegion() {
      try {
        const geocode = await window.ymaps.geocode(this.userLocation, { results: 1, kind: 'locality' });
        const [geoObject] = geocode.geoObjects.toArray(); // Получаем массив объектов и деструктурируем
        if (!geoObject) {
          throw new Error('Не удалось определить местоположение');
        }

        const [administrativeArea] = geoObject.getAdministrativeAreas(); // Деструктурируем результат
        if (!administrativeArea) {
          throw new Error('Административная область не найдена');
        }

        this.setUserLocationName(administrativeArea);
      } catch (error) {
        this.$services.MessageService.error('Не удалось определить местоположение');
        console.error(error.message, error.stack);
      }
    },
    logoHandle() {
      this.$router.push(`/${this.layer}`);
    },
    ...mapActions('location', ['setUserLocationName', 'setShowLocationChoiceModal'])
  },
  computed: {
    ...mapGetters('location', ['userLocation', 'userLocationName']),
    ...mapGetters('layer', ['layer'])
  }
}
</script>

<style scoped lang="scss">
.sidebar-top :hover>img {
  opacity: .5;
}

.location-block {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-wrap: nowrap;

  .caption {
    font-size: 18px;
    color: #8C8C8C;
    user-select: none;
  }
}
</style>
