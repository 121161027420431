<template>
  <div class="sidebar-select">
    <template>
      <el-tooltip :content="hintDirections" :disabled="hintDisabled" placement="top-start" popper-class="hint">
        <el-select class="form-select" :value="selectedDirections" placeholder="Выбрать категорию продуктов"
          @change="changeHandle($event)" multiple>
          <el-option class="select-checkbox" :class="{ 'selected': isSelected(item.id) }"
            v-for="(item, index) in directions" :key="index" :value="item.id" :label="item.name" />
        </el-select>
      </el-tooltip>
    </template>
    <button v-if="selectedDirections.length" class="sidebar-select__button" :class="{ 'fill': isFillButton }"
      @click="clickButtonHandle">
      <img v-if="$route.path.includes('filters')" src="@/assets/images/svg/close_white.svg">
      <img v-else-if="this.hasDataInFilterProperties" src="@/assets/images/svg/options.svg">
      <img v-else src="@/assets/images/svg/options_black.svg">
    </button>
  </div>
</template>

<script lang="js">
import { mapActions, mapGetters } from 'vuex';
import entity from '@/helpers/FetchEntities';

export default {
  name: 'SidebarDirectionList',
  methods: {
    async changeHandle(value) {
      this.startLoading();

      try {
        this.selectDirection(value);

        if (value.length === 0) {
          this.$router.push({ path: `/${this.layer}` });
        } else {
          await entity.filter();
          if (!this.$route.path.includes('filters')) {
            this.$router.push({ path: `/${this.layer}/filters` });
          }
        }

        this.stopLoading();
      } catch (error) {
        this.$services.MessageService.error('Не удалось загрузить данные');
        console.error(error.message, error.stack);
        this.stopLoading();
      }
    },
    clickButtonHandle() {
      if (this.$route.path.includes('filters')) {
        this.$router.push({ path: `/${this.layer}` });
      } else {
        this.$router.push({ path: `/${this.layer}/filters` });
      }
    },
    isSelected(id) {
      return this.selectedDirections.includes(id);
    },
    ...mapActions('filter', ['selectDirection']),
    ...mapActions('loader', ['startLoading', 'stopLoading'])
  },
  computed: {
    hintDirections() {
      const directionsFilter = this.directions.filter(item => this.selectedDirections.includes(item.id));
      return directionsFilter.map((item) => item.name).join(', ');
    },
    hintDisabled() {
      return this.hintDirections.split(', ').length < 2;
    },
    isFillButton() {
      return this.$route.path.includes('filters') || this.hasDataInFilterProperties;
    },
    hasDataInFilterProperties() {
      return Object.entries(this.filterValues).some(([key, value]) => {
        // Пропускаем свойство `directions`
        if (key === 'directions') {
          return false;
        }
        // Проверяем, является ли свойство массивом и содержит ли оно хотя бы одну строку
        return Array.isArray(value) && value.length > 0;
      });
    },
    ...mapGetters('layer', ['layer']),
    ...mapGetters('filter', ['selectedDirections', 'filterValues', 'directions'])
  }
}
</script>

<style lang="scss">
.sidebar-select {
  position: relative;
  display: flex;
  margin-bottom: 16px;

  &__button {
    width: 46px;
    height: 42px;
    padding: 0;
    background-color: #FFFFFF;
    border: 1px solid #000000;
    cursor: pointer;
    transition: background-color .5s ease;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 26px;
      height: 26px;
    }
  }

  &__button.fill {
    background-color: #58BCAF;
    border-color: #58BCAF;
  }
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.select-checkbox::after {
  content: '';
  position: absolute;
  top: 17px;
  left: 14px;
  width: 12px;
  height: 12px;
  background: url('../../assets/images/svg/checked.svg') no-repeat;
  opacity: 0;
  z-index: 2;
}

.el-select-dropdown.is-multiple .__item.select-checkbox.selected::after {
  opacity: 1;
}
</style>
