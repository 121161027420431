import DirectionColors from '@/helpers/DirectionColors';

function handleMouseLeave(e) {
    e.get('target').getOverlaySync().getLayoutSync().getElement().children[0].classList.remove('cluster_hovered');
}

function handleMouseEnter(e) {
    e.get('target').getOverlaySync().getLayoutSync().getElement().children[0].classList.add('cluster_hovered');
}

function getFilteredData(activeLayer, visibleObjectsHtml) {
    if (activeLayer === 'objects') {
      const obj = this.$store.getters['getObjects'];
      return obj.filter(el => visibleObjectsHtml.includes(el.id));
    }

    const partners = this.$store.getters['getPartners'];
    return partners.filter(el => visibleObjectsHtml.includes(el.id));
}

function handleDoubleClick(clusterer) {
    const activeLayer = this.layer;
    const zoom = this.zoom;
    if (zoom > 15) {
      const geoObjects = clusterer.getGeoObjects();
      const visibleGeoObjects = window.ymaps.geoQuery(geoObjects).searchIntersect(this.map);
      const visibleObjectsHtml = [];

      visibleGeoObjects.each((x) => {
        const content = x.properties.get('data').find(el => el.objects).objects;
        visibleObjectsHtml.push(...content);
      });

      const filteredData = getFilteredData(activeLayer, visibleObjectsHtml);
      this.$store.dispatch('setSearchResults', filteredData);
      this.$router.push('/results');
    }
}

function addPlacemarkEvents(clusterer, zoomIn) {
    const placemark = clusterer.getGeoObjects()[0];

    placemark.events.add(['dblclick'], e => handleDoubleClick(clusterer));
    placemark.events.add(['click'], e => zoomIn(e));
    placemark.events.add(['mouseenter'], e => handleMouseEnter(e));
    placemark.events.add(['mouseleave'], e => handleMouseLeave(e));
}

function createClusterer(center, pieChartOptions, objects) {
    const clusterer = new window.ymaps.Clusterer();
    const placemark = new window.ymaps.Placemark(
      center,
      {
        data: pieChartOptions,
        iconContent: objects ? objects.length : ''
      },
      {
        iconLayout: 'default#pieChart',
        iconPieChartRadius: window.innerWidth > 767 ? 35 : 25,
        iconPieChartCoreRadius: window.innerWidth > 767 ? 28 : 20,
        iconPieChartStrokeWidth: 0,
        hasBalloon: false
      }
    );
    clusterer.add(placemark);
    return clusterer;
}

function updatePieChartDirections(pieChartOptions, directions, objects) {
    directions.forEach(direction => {
      const color = DirectionColors[direction];
      const fItem = pieChartOptions.find(option => option.color === color);
      if (fItem) {
        fItem.weight++;
        fItem.objects = objects.map(el => el.properties.get('markerId'));
      }
    });
}

function getDirections(item) {
    return Array.isArray(item.directions) ? [...new Set(item.directions.map(direction => direction.code.toLowerCase()))] : [];
}

function updatePieChartWeights(pieChartOptions, objects, items) {
    objects.forEach(obj => {
      const item = items.find(marker => marker.id === obj.properties.get('markerId'));
      if (item) {
        const directions = getDirections(item);
        updatePieChartDirections(pieChartOptions, directions, objects);
      }
    });
}

function initializePieChartOptions() {
    return Object.values(DirectionColors).map(directionColor => ({ weight: 0, color: directionColor }));
}

const createCluster = (center, objects, items, zoomIn) => {
    const pieChartOptions = initializePieChartOptions();
    updatePieChartWeights(pieChartOptions, objects, items);

    const clusterer = createClusterer(center, pieChartOptions, objects);
    addPlacemarkEvents(clusterer, zoomIn);

    return clusterer;
}

export default createCluster;
