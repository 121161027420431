const state = {
    userLocation: null, // Локация пользователя
    userLocationName: '', // Название локации пользователя
    currentLocation: null, // Текущая локация
    isShowLocationChoiceModal: false, // Показать модальное окно выбора локации
    isVisibleLocationConfirm: false, // Показать подтверждение выбора локации
    locationConfirmMode: '' // Режим подтверждения выбора локации
};

const actions = {
    setLocation({ commit }, { coords, zoom }) {
        commit('SET_USER_LOCATION', coords);
        commit('SET_CURRENT_LOCATION', { coords, zoom: zoom || 10 });
    },
    setUserLocation({ commit }, coords) {
        commit('SET_USER_LOCATION', coords);
    },
    setUserLocationName({ commit }, name) {
        commit('SET_USER_LOCATION_NAME', name);
    },
    setCurrentLocation({ commit }, { coords, zoom }) {
        commit('SET_CURRENT_LOCATION', { coords, zoom: zoom || 10 });
    },
    setShowLocationChoiceModal({ commit }, value) {
        commit('SET_SHOW_LOCATION_CHOICE_MODAL', value);
    },
    setVisibleLocationConfirm({ commit }, value) {
        commit('SET_VISIBLE_LOCATION_CONFIRM', value);
    },
    setLocationConfirmMode({ commit }, mode) {
        commit('SET_LOCATION_CONFIRM_MODE', mode);
    }
};

const mutations = {
    SET_USER_LOCATION(state, coords) {
        state.userLocation = coords;
    },
    SET_USER_LOCATION_NAME(state, name) {
        state.userLocationName = name;
    },
    SET_CURRENT_LOCATION(state, location) {
        state.currentLocation = location;
    },
    SET_SHOW_LOCATION_CHOICE_MODAL(state, value) {
        state.isShowLocationChoiceModal = value;
    },
    SET_VISIBLE_LOCATION_CONFIRM(state, value) {
        state.isVisibleLocationConfirm = value;
    },
    SET_LOCATION_CONFIRM_MODE(state, mode) {
        state.locationConfirmMode = mode;
    }
};

const getters = {
    userLocation: state => state.userLocation,
    userLocationName: state => state.userLocationName,
    currentLocation: state => state.currentLocation,
    isShowLocationChoiceModal: state => state.isShowLocationChoiceModal,
    isVisibleLocationConfirm: state => state.isVisibleLocationConfirm,
    locationConfirmMode: state => state.locationConfirmMode
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
