import store from '@/store';
import SessionHelper from '@/helpers/SessionHelper';

function confirmLocation() {
  store.dispatch('location/setLocationConfirmMode', 'confirm');
  store.dispatch('location/setVisibleLocationConfirm', false);
}

function setLocationByGeolocation() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      position => {
        const coords = [position.coords.latitude, position.coords.longitude];
        SessionHelper.setLocationCookie(coords);
        store.dispatch('location/setLocation', { coords });
        confirmLocation();
      },
      () => {
        // Вбиваем координаты Москвы и уточняем - поменять или оставить?
        store.dispatch('location/setLocation', { coords: [55.7558, 37.6173] });
        store.dispatch('location/setLocationConfirmMode', 'manual');
        store.dispatch('location/setVisibleLocationConfirm', true);
      }
    );
  }
}

function setLocationCookie() {
  const coords = SessionHelper.getLocationCookie();
  if (coords) {
    store.dispatch('location/setLocation', { coords: JSON.parse(coords) });
    confirmLocation();
    return true;
  }
  return false;
}

function getGeolocation() {
  if (!setLocationCookie()) {
    setLocationByGeolocation();
  }
}

export default {
  defineGeolocation() {
    if (!SessionHelper.checkPrevSession()) {
      store.dispatch('cookie/setCookieVisible', true);
      store.dispatch('layer/setLayerModalVisible', true);
    } else if (!SessionHelper.checkTodaySession()) {
      SessionHelper.setSessionCookie();
    }

    getGeolocation();
  }
}
