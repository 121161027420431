<template>
  <div class="cookies">
    <p class="cookies__content">
      РЕХАУ обрабатывает Cookies с целью персонализации сервисов и чтобы пользоваться веб‑сайтом было удобнее. Вы можете
      запретить обработку Cookies в настройках браузера. Пожалуйста, ознакомьтесь с политикой использования
      <a class="cookies__link" href="https://rhsolutions.ru/cookies/" target="_blank">Cookies</a>
      , где мы подробно рассказываем, как РЕХАУ обрабатывает и защищает ваши персональные данные на странице.
    </p>
    <button class="btn btn-green cookies__btn" @click="acceptClose">Принимаю</button>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import SessionHelper from '@/helpers/SessionHelper';

export default {
  name: 'CookieAccept',
  methods: {
    acceptClose() {
      SessionHelper.setSessionCookie();
      this.setCookieVisible(false);
    },
    ...mapActions('cookie', ['setCookieVisible'])
  }
}
</script>

<style scoped lang="scss">
.cookies {
  position: fixed;
  right: 40px;
  bottom: 30px;
  max-width: 365px;
  min-height: 200px;
  background: #000000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  padding: 20px;
  z-index: 1;

  &__content {
    color: #FFFFFF;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  &__link {
    color: #FFFFFF;
    text-decoration: underline;
  }

  &__link:hover {
    color: #4fc7b5;
  }

  &__btn {
    width: 100%;
  }

  @media (max-width: 768px) {
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    max-width: none;
    min-height: 0;
    padding: 15px 10px 20px;

    &__content {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 15px;
    }
  }
}
</style>
