<template>
  <div class="wrapper">
    <Sidebar />
    <main class="main">
      <MapComponent />
      <AuthReminder v-if="isAuthenticated" />
      <LayerSwitchMini />
      <ProductList />
      <CookieAccept v-if="isCookieVisible" />
      <LayerSwitch v-if="isLayerModalVisible" />
      <LocationConfirm v-if="isVisibleLocationConfirm" :mode="locationConfirmMode" />
      <LocationChoiceModal v-if="isShowLocationChoiceModal" />
    </main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GeolocationHelper from '@/helpers/GeolocationHelper';
import Sidebar from '@/components/Sidebar.vue';
import MapComponent from '@/components/Map.vue';
import AuthReminder from '@/components/AuthReminder.vue';
import LayerSwitch from '@/components/LayerSwitch.vue';
import LayerSwitchMini from '@/components/LayerSwitchMini.vue';
import CookieAccept from '@/components/CookieAccept.vue';
import ProductList from '@/components/ProductList.vue';
import LocationConfirm from '@/components/LocationConfirm.vue';
import LocationChoiceModal from '@/components/modal/LocationChoiceModal.vue';

export default {
  name: 'LayoutSidebar',
  components: {
    Sidebar,
    MapComponent,
    AuthReminder,
    LayerSwitch,
    LayerSwitchMini,
    CookieAccept,
    ProductList,
    LocationConfirm,
    LocationChoiceModal
  },
  created() {
    GeolocationHelper.defineGeolocation();
  },
  computed: {
    ...mapGetters('location', ['isShowLocationChoiceModal', 'isVisibleLocationConfirm', 'locationConfirmMode']),
    ...mapGetters('cookie', ['isCookieVisible']),
    ...mapGetters('layer', ['isLayerModalVisible']),
    ...mapGetters('user', ['isAuthenticated'])
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  height: 100%;
}

.main {
  position: relative;
  height: 100%;
  width: calc(100% - 393px);
  margin-left: auto;
}

@media (max-width: 1023px) {
  .wrapper {
    flex-direction: column;
    overflow: hidden;
  }

  .main {
    width: 100vw;
    margin-left: 0;
  }
}
</style>
