const state = {
    map: null
};

const actions = {
    setMap({ commit }, map) {
        commit('SET_MAP', map);
    },
    removeAllObjects({ commit }) {
        commit('REMOVE_ALL_OBJECTS');
    },
    increaseZoom({ commit }) {
        commit('INCREASE_ZOOM');
    },
    decreaseZoom({ commit }) {
        commit('DECREASE_ZOOM');
    }
};

const mutations = {
    SET_MAP(state, map) {
        state.map = map;
    },
    REMOVE_ALL_OBJECTS(state) {
        state.map.geoObjects.removeAll();
    },
    INCREASE_ZOOM(state) {
        state.map.setZoom(state.map.getZoom() + 1, { checkZoomRange: true });
    },
    DECREASE_ZOOM(state) {
        try {
            state.map.setZoom(state.map.getZoom() - 1, { checkZoomRange: true });
        } catch (e) {
            console.error(e.message);
        }
    }
};

const getters = {
    map: state => state.map,
    bounds: state => {
        if (!state.map) {
            return null;
        }
        return state.map.getBounds();
    },
    zoom: state => state.map.getZoom()
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
