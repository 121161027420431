import axios from '@/requests/axios';

const directions = async () => {
    const response = await axios.get('/api/v1/map/filter/directions');
    return response.data || null;
};

const partnerTypes = async () => {
    const response = await axios.get('/api/v1/map/filter/partner-types');
    return response.data || null;
};

const partnerAttributes = async () => {
    const response = await axios.get('/api/v1/map/filter/partner-attributes');
    return response.data || null;
};

const productGroups = async () => {
    const response = await axios.get('/api/v1/map/filter/product-groups');
    return response.data || null;
};

const filters = {
    directions,
    partnerTypes,
    partnerAttributes,
    productGroups
};

export default filters;
