import axios from '../axios';

const info = async () => {
  try {
    const response = await axios.get('/api/v1/map/user/info');
    return response.data || {};
  } catch (error) {
    return {};
  }
}

const user = {
  info
};

export default user;
